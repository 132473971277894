import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MdWeb } from "react-icons/md"
import { BsBook, BsCodeSlash } from "react-icons/bs"
import styled from "styled-components"
import ThemeContext from "../context/ThemeContext"

import { Link } from "gatsby"

// index は呼び出し元で生成されたもの（データSchemaで定義されているものではない）
const Project = ({ description, title, image, index, point, contents }) => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <article className="project">
          <div className="project-info">
            <span className="project-number">0{index + 1}.</span>
            <a href={contents.url[0]}>
              <h3
                className={`project-title ${
                  theme.language === "JP" ? "p3PjJP" : "p3Pj"
                }`}
              >
                {title}
              </h3>
            </a>

            <P className="project-desc">{description.description}</P>
            <GatsbyImage
              image={getImage(image)}
              className="project-img"
              alt={title}
            ></GatsbyImage>
            <div className="project-stack">
              {contents.tags.map((tag, index) => {
                return (
                  <span className="tech-tag" key={index}>
                    {tag}
                  </span>
                )
              })}
            </div>
            {/* {contents.url_icon[0] != "none" && (
              <div className="project-links">
                {contents.url_icon.map((url_icon, index) => {
                  return (
                    <>
                      {url_icon !== "none" && (
                        <a href={contents.url[index]}>
                          {url_icon === "publication" && (
                            <BsBook className="project-icon"></BsBook>
                          )}
                          {url_icon === "web" && (
                            <MdWeb className="project-icon"></MdWeb>
                          )}
                          {url_icon === "source_code" && (
                            <BsCodeSlash className="project-icon"></BsCodeSlash>
                          )}
                        </a>
                      )}
                    </>
                  )
                })}
              </div>
            )} */}
          </div>
        </article>
      )}
    </ThemeContext.Consumer>
  )
}
export default Project

const P = styled.p`
  font-family: "Shippori Mincho", sans-serif;
`
