import React from "react"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import SEO from "../components/Seo"
import ThemeContext from "../context/ThemeContext"
import Dataviz from "../components/DataViz"

const ProjectsPage = ({
  data: {
    allContentfulProject: { nodes: projects },
  },
}) => {
  return (
    <>
      <SEO title="Kentaro Takahira" />
      <ThemeContext.Consumer>
        {theme => (
          <main>
            <section className="projects-page">
              <Projects
                title="Project"
                projects={projects.filter(p => p.language == theme.language)}
              />
              <Dataviz title="Project" />
            </section>
          </main>
        )}
      </ThemeContext.Consumer>
    </>
  )
}

export default ProjectsPage

export const query = graphql`
  {
    allContentfulProject(sort: { fields: point, order: DESC }) {
      nodes {
        featured
        description {
          description
        }
        id
        point
        contents {
          tags
          url_icon
          url
          id
        }
        title
        language
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
      }
    }
  }
`
