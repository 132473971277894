import React from "react"
import Title from "./Title"
import Project from "./Project"
import { Link } from "gatsby"
import styled from "styled-components"
import { AiOutlineBarChart } from "react-icons/ai"
import ThemeContext from "../context/ThemeContext"

const Projects = ({ projects, title, showLink }) => {
  return (
    <section className="section projects">
      <ThemeContext.Consumer>
        {theme => (
          <>
            <Title title={title} />
            <div className="section-center projects-center">
              <h3
                className={`${
                  theme.language === "JP"
                    ? "h3ArtJP datavis-section-title"
                    : "datavis-section-title"
                }`}
              >
                <AiOutlineBarChart className="reactIconinText" />{" "}
                {theme.language == "EN" ? "Project" : "プロジェクト"}
              </h3>
              <div className="my-panel-container projects-cards">
                {projects
                  .sort((a, b) => a.point - b.point)
                  .map((project, index) => {
                    return (
                      <Project key={Project.id} index={index} {...project} />
                    )
                    // これでProjectに入っているデータを全部渡せる
                  })}
              </div>

              {showLink && (
                <Link to="/projects" className="btn center-btn">
                  projects
                </Link>
              )}
            </div>
          </>
        )}
      </ThemeContext.Consumer>
    </section>
  )
}

export default Projects

const H4jp = styled.h4`
  font-family: "Shippori Mincho", sans-serif;
  /* font-weight: bold; */
`
